<template>
  <header
    ref="el"
    class="relative flex h-screen items-end overflow-hidden text-white"
  >
    <!-- vue fade image transition on src change -->
    <Transition name="fade-img">
      <div
        v-if="slides[currentIndex]?.image"
        :key="`img-${currentIndex}`"
        class="absolute inset-0 -z-1"
      >
        <AppImage
          :src="slides[currentIndex]?.image"
          :style="`object-position: ${slides[currentIndex]?.image.focus_css};`"
          class="h-full w-full object-cover"
          :alt="
            $i18n.locale === 'fr'
              ? slides[currentIndex]?.image.alt_fr
              : slides[currentIndex]?.image.alt
          "
          @load="loaded = true"
        />
      </div>

      <FullscreenVideo
        v-else
        :key="`video-${currentIndex}`"
        :src="slides[currentIndex]?.youtube_url"
        class="absolute inset-0 -z-1 h-full w-full scale-125"
        @playing="stopInterval"
        @ended="setCurrent(currentIndex + 1)"
      />
    </Transition>

    <!-- Gradient Top -->
    <div
      class="pointer-events-none absolute inset-x-0 top-0 -z-1 h-1/4 bg-gradient-to-b from-blue-dark-900 opacity-60"
    />

    <!-- Gradient Bottom -->
    <div
      class="pointer-events-none absolute inset-x-0 bottom-0 -z-1 h-1/3 bg-gradient-to-t from-blue-dark-900 opacity-90"
    />

    <Container
      class="flex flex-1 flex-col justify-between pb-14 xl:flex-row xl:items-end"
    >
      <div
        class="flex flex-col space-y-4 lg:flex-row lg:items-end lg:space-y-0"
      >
        <h1
          v-if="data.heading"
          class="fade-in max-w-[28.75rem] hyphens-auto text-5xl/10 font-medium md:text-7xl"
          v-html="$replaceHtmlTags(data.heading)"
        />

        <p v-if="data.text" class="fade-in max-w-lg md:text-md lg:pl-14">
          {{ data.text }}
        </p>
      </div>

      <!-- Slide Indicator -->
      <div
        class="mt-8 flex w-full flex-row-reverse items-end justify-between self-end xl:w-auto xl:flex-row"
      >
        <ul class="flex space-x-2 xl:mr-12">
          <li v-for="(slide, index) in slides" :key="index">
            <button
              class="group -my-3 py-3"
              :aria-label="`${$t('accessibility.switch_to_slide_nr')}: ${
                index + 1
              }`"
              @click="setCurrent(index)"
            >
              <span
                class="inline-block h-[0.1875rem] w-6 rounded-full transition-colors"
                :class="
                  currentIndex === index
                    ? 'bg-blue-800'
                    : 'bg-white/40 group-hover:bg-white'
                "
              />
            </button>
          </li>
        </ul>

        <button
          class="slide-down group -mt-1"
          :aria-label="$t('accessibility.scroll_to_content')"
          @click="scrollTo()"
        >
          <ArrowGrowIcon animate stroke-width="2" class="w-6 rotate-90" />
        </button>
      </div>
      <!-- END Slide Indicator -->
    </Container>
  </header>
</template>

<script setup>
const props = defineProps({
  data: { type: Object, required: true },
})

const { $gsap } = useNuxtApp()
const { setIsWhite } = useNavigationStore()

const el = ref(null)
const currentIndex = ref(0)
const loaded = ref(false)
const interval = ref(null)

// filter out empty slides
const slides = computed(() =>
  props.data.slides.filter((slide) => {
    return slide.image || slide.youtube_url
  })
)

// set current image in a loop with a delay
const startInterval = () => {
  interval.value = setInterval(() => {
    if (!loaded.value) return

    currentIndex.value = (currentIndex.value + 1) % slides.value.length
  }, 6000)
}

// set current image on click
const setCurrent = (index) => {
  clearInterval(interval.value)
  currentIndex.value = index
  startInterval(index)
}

const stopInterval = () => {
  clearInterval(interval.value)
}

const scrollTo = () => {
  const selector = '[scroll-to-index="0"]'
  const element = document.querySelector(selector)

  if (!element) {
    return
  }

  const headerOffset = 250
  const elementPosition = element.offsetTop
  const offsetPosition = elementPosition - headerOffset

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  })
}

// Animation
onMounted(() => {
  setIsWhite(false)

  let q = $gsap.utils.selector(el.value)

  $gsap
    .timeline()
    .to(q('.fade-in'), {
      opacity: 1,
      y: 0,
      duration: 0.8,
      stagger: 0.15,
      ease: 'power2.out',
      delay: 0.4,
    })
    .to(q('.slide-down'), {
      opacity: 1,
      y: 0,
      duration: 0.4,
      stagger: 0.15,
      ease: 'power2.out',
    })

  startInterval()
})
</script>

<style scoped>
.fade-img-enter-active,
.fade-img-leave-active {
  transition: opacity 2s;
}

.fade-img-enter-from,
.fade-img-leave-to {
  opacity: 0;
}

.fade-in {
  @apply opacity-0 translate-y-10;
}

.slide-down {
  @apply opacity-0 -translate-y-10;
}
</style>
